import React from 'react'
import { graphql, Link } from "gatsby"
import styled from 'styled-components'

import NewsLetterForm from '../components/UI/newsletter-form'
import ShareBanner from "../components/UI/share-banner"
import Footer from '../components/UI/footer'
import AppNavBar from '../components/UI/Navbar-pages'
import BlogImage from '../components/UI/blog-image'
import Arrow from '../../static/assets/arrow.svg'


const Case = styled.div `
    margin: 0 10%;
    padding-top: 2em;

   .back-button {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: fit-content;
    align-items: center;
    text-decoration: none;
    color: #000;

    :after {
      position: absolute;
      bottom: 1em;
      left: 0;
      right: 0;
      width: 0%;
      content: "";
      color: transparent;
      background: black;
      height: 1px;
      transition: all 0.4s ease-in;
    }

    :hover {
      color: #888;
      transition: all 0.3s;

      ::after {
        width: 100%;
        color: #888;
      }
    }

   }
`


const InsightPage = ({ data }) => {
    const { title, date, type } = data.markdownRemark.frontmatter;
    const image = data.markdownRemark.frontmatter.blog_image;
    const body = data.markdownRemark.html;

    return (  
        <>
        <AppNavBar>
          <BlogImage image={image} title={title} client={type} />
        </AppNavBar>
        <div className="main-section">
            <Case>
            <h3 className="blog">{date}</h3>
            <h1 className="blog">{title}</h1>
            <div dangerouslySetInnerHTML={{ __html: body }}/>
            
              <Link to="/insights" className="back-button">
                
                  <Arrow style={{paddingRight: '1em'}} />
                  <h3 className="blog">Back to Insights & more articles</h3>
               
              </Link>
           
              <NewsLetterForm />
              <ShareBanner />
            </Case>
            <Footer />
        </div>
        </>
    )
}

export const pageQuery = graphql`
  query InsightBySlug($slug: String!){
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date
        title
        type
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [PNG, WEBP, AVIF], layout: FULL_WIDTH)
          }
        }
        blog_image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [PNG, WEBP, AVIF], layout: FULL_WIDTH)
          }
        }
        color
      }
    }
  }
`

export default InsightPage