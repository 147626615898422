import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import styled from 'styled-components'

const TitleImage = styled.div `
    position: relative;
    height: 280px;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: -50px;
    overflow: hidden;

  .content-shape {
      top: 0; left: 0;
      background-color: #fff;
      margin-top: -1px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: fit-content;
      clip-path: polygon(100% 100%, 0 100%, 30% 0, 100% 0);
      box-shadow: var(--tuva-box-shadow);
  }

  .content-block {
        padding: 0 5vw;
        margin-left: 8vw;

        h1 {
            font-size: 3em;
        }

    @media (max-width: 768px){
        h1 {
            font-size: 2em;
        }
    }
  }

    
`

const BlogImage = (props) => {

    return (
        <TitleImage>
            <GatsbyImage className="background" 
            image={props.image.childImageSharp.gatsbyImageData} 
            alt={props.title} />
            <div className="content-shape">
                <div className="content-block">
                    <h1>{props.client}</h1>
                </div>
              </div>  
          </TitleImage>
    )
}

export default BlogImage